import React, { useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'
import { Row, Col } from 'react-simple-flex-grid'
import { graphql } from 'gatsby'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import Img from 'gatsby-image'
import { FormattedMessage } from 'gatsby-plugin-intl'
import classNames from 'classnames'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import { useWindowSize } from '_hooks'
import { IMG_AS_HERO_CONTAINER_STYLES, IMG_AS_HERO_IMG_STYLES } from '_utils/config'
import { Container, Heading, ScrollArrow, Text, Image } from '_atoms'
import { Quote, ProudCases, ColorRibbon } from '_molecules'
import { Layout } from '_templates'
import { SEO, ViewportObserver } from '_organisms'
import { AppContext } from '_context'
import variables from '_config/css-variables'
import EQILogo from '_images/svgs/eqi-btg-logo.inline.svg'
import { rawToJSON } from '_utils/functions'

import styles from './styles.module.css'

const EQICaseStudy = ({ data }) => {
  const { setNavbarColor, setNavbarLight } = useContext(AppContext)
  const content = data.contentfulEqiCaseStudy
  const { quote } = content

  const visualDesignColors = [
    variables['eqi-secondary'],
    variables['eqi-primary'],
    variables['eqi-accent'],
    variables['eqi-accent-dark'],
  ]

  const visualDesignTextColors = ['white', 'white', 'white', 'white']

  const { results1, results2, results3 } = content

  const resultsOptions = {
    renderMark: {
      bold: text => <strong className={styles.resultsHighlight}>{text}</strong>,
    },
    renderNode: {
      paragraph: (node, children) => (
        <Text size="18" color="white">
          {children}
        </Text>
      ),
      hyperlink: (node, children) => {
        const { data: eqiData } = node
        return (
          <a
            className={styles.resultsLink}
            href={eqiData.uri}
            rel="noopener noreferrer"
            target="_blank"
          >
            {children}
          </a>
        )
      },
    },
  }

  const [width] = useWindowSize()
  const isColumnLayout = width < 992

  const [y, setY] = useState(0)

  useScrollPosition(
    ({ currPos }) => {
      setY(currPos.y * -1)
    },
    [],
    null,
    false,
    300
  )

  useEffect(() => {
    setNavbarColor(variables['eqi-primary'])
    setNavbarLight(false)
  }, [setNavbarColor, setNavbarLight])

  return (
    <Layout navbarColorOverride={variables['eqi-primary']}>
      <SEO
        title={content.pageTitle || 'Web &amp; Mobile App Design and Development Company'}
        description={
          content.pageDescription || 'Web &amp; Mobile App Design and Development Company'
        }
        thumbnail={content.pageThumbnail.file.url}
      />

      <main className={styles.hero}>
        <Container>
          <Row className={styles.heroRow}>
            <Col xs={12} sm={12} md={6} lg={6} className={styles.heroText}>
              <Fade distance="10%" bottom>
                <EQILogo className={styles.heroLogo} />
              </Fade>

              <Fade distance="10%" delay={200} bottom>
                <Text color="white" size="28" splitParagraphs className={styles.heroParagraph}>
                  {content.heroDescription.internal.content}
                </Text>
              </Fade>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} className={styles.heroImages}>
              {content.heroImages.map((image, index) => (
                <Fade distance="10%" delay={(index + 1) * 300} bottom key={image.description}>
                  <Img
                    fluid={image.fluid}
                    alt={image.description}
                    className={styles.heroImagesImage}
                    fadeIn={false}
                    imgStyle={{ objectFit: 'contain' }}
                  />
                </Fade>
              ))}
            </Col>

            <Fade distance="10%" delay={350} bottom>
              <ScrollArrow className={styles.heroArrowButton} isButton scrollId="challenge" />
            </Fade>
          </Row>
        </Container>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['eqi-primary'])
            setNavbarLight(false)
          }}
        />
      </main>

      <section className={styles.challenge} id="challenge">
        <Image
          src={content.challengeImage.file.url}
          alt={content.challengeImage.description}
          className={styles.challengeImage}
        />

        <Container>
          <div className={styles.challengeWrapper}>
            <Fade distance="10%" bottom>
              <Heading
                type="h1"
                bold
                color="white"
                className={classNames(styles.heading, styles.challengeHeading)}
              >
                {content.challengeTitle}
                <span className={styles.dot}>.</span>
              </Heading>
            </Fade>

            <Fade delay={200} distance="10%" bottom>
              <Text color="white" size="22" splitParagraphs className={styles.description}>
                {content.challengeDescription.internal.content}
              </Text>
            </Fade>
          </div>
        </Container>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['eqi-secondary'])
            setNavbarLight(false)
          }}
        />
      </section>

      <section className={styles.userExperience}>
        <Img
          fluid={content.userExperienceBg.fluid}
          alt={content.userExperienceBg.description}
          style={IMG_AS_HERO_CONTAINER_STYLES}
          imgStyle={IMG_AS_HERO_IMG_STYLES}
          className={styles.userExperienceBg}
        />
        <Container>
          <Row>
            <Col xs={12} sm={12} md={5} lg={5}>
              <Fade distance="10%" bottom>
                <Heading type="h4" bold color="eqiAccent" className={styles.heading}>
                  {content.userExperienceTitle}
                  <span className={styles.dot}>.</span>
                </Heading>
              </Fade>

              <Fade delay={200} distance="10%" bottom>
                <Text
                  color="white"
                  size="22"
                  splitParagraphs
                  className={classNames(styles.description, styles.userExperienceText)}
                >
                  {content.userExperienceDescription.internal.content}
                </Text>
              </Fade>
            </Col>
            <Col xs={12} sm={12} md={7} lg={7} className={styles.userExperienceImages}>
              {content.userExperienceImages.map((image, index) => (
                <Fade distance="10%" delay={(index + 1) * 300} bottom key={image.fluid.src}>
                  <Img
                    fluid={image.fluid}
                    alt={image.description}
                    fadeIn={false}
                    imgStyle={{ objectFit: 'contain' }}
                    className={styles.userExperienceImagesImage}
                  />
                </Fade>
              ))}
            </Col>
          </Row>
        </Container>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['eqi-primary'])
            setNavbarLight(false)
          }}
        />
      </section>

      <section className={styles.visualDesign}>
        <ColorRibbon
          side="left"
          colors={visualDesignColors}
          textColors={visualDesignTextColors}
          center
          className={styles.visualDesignRibbon}
        />
        <Container className={styles.visualDesignContainer}>
          <div className={styles.visualDesignWrapper}>
            <Fade distance="10%" bottom>
              <Heading type="h4" bold color="eqiPrimary" className={styles.heading}>
                {content.visualDesignTitle}
                <span className={styles.dot}>.</span>
              </Heading>
            </Fade>

            <Fade delay={200} distance="10%" bottom>
              <Text color="dark" size="22" splitParagraphs className={styles.description}>
                {content.visualDesignDescription.internal.content}
              </Text>
            </Fade>
          </div>
        </Container>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['color-gray-lightest'])
            setNavbarLight(true)
          }}
        />
      </section>

      <section className={styles.payments}>
        <Container>
          <Row className={styles.paymentsRow}>
            <Col xs={12} sm={12} md={6} lg={6}>
              <Fade distance="10%" bottom>
                <Heading type="h4" bold color="white" className={styles.heading}>
                  {content.paymentsTitle}
                  <span className={styles.dot}>.</span>
                </Heading>
              </Fade>

              <Fade delay={200} distance="10%" bottom>
                <Text color="white" size="22" splitParagraphs className={styles.description}>
                  {content.paymentsDescription.internal.content}
                </Text>
              </Fade>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} className={styles.paymentsImages}>
              {content.paymentsImages.map((image, index) => (
                <Fade distance="10%" delay={(index + 1) * 300} bottom key={image.fluid.src}>
                  <Img
                    fluid={image.fluid}
                    alt={image.description}
                    fadeIn={false}
                    imgStyle={{ objectFit: 'contain' }}
                    className={styles.paymentsImagesImage}
                  />
                </Fade>
              ))}
            </Col>
          </Row>
        </Container>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['eqi-primary'])
            setNavbarLight(false)
          }}
        />
      </section>

      <section className={styles.simulation}>
        {isColumnLayout ? (
          <Image
            src={content.simulationBgMobile.file.url}
            alt={content.simulationBgMobile.description}
            className={styles.simulationBgMobile}
          />
        ) : (
          <Image
            src={content.simulationBg.file.url}
            alt={content.simulationBg.description}
            className={styles.simulationBgDesktop}
          />
        )}
        <Container>
          <Row className={styles.simulationRow}>
            <Col xs={12} sm={12} md={6} lg={6} className={styles.simulationImageWrapper}>
              <Img
                fluid={content.simulationImage.fluid}
                alt={content.simulationImage.description}
                fadeIn={false}
                className={styles.simulationImage}
                imgStyle={{
                  objectFit: 'contain',
                  objectPosition: 'left center',
                }}
              />
            </Col>

            <Col xs={12} sm={12} md={6} lg={6} className={styles.simulationTexts}>
              <Fade distance="10%" bottom>
                <Heading type="h4" bold color="white" className={styles.heading}>
                  {content.simulationTitle}
                  <span className={styles.dot}>.</span>
                </Heading>
              </Fade>

              <Fade delay={200} distance="10%" bottom>
                <Text color="dark" size="22" splitParagraphs className={styles.description}>
                  {content.simulationDescription.internal.content}
                </Text>
              </Fade>
            </Col>
          </Row>
        </Container>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['eqi-accent'])
            setNavbarLight(true)
          }}
        />
      </section>

      <section className={styles.goals}>
        <Container>
          <div className={styles.goalsRow}>
            <Fade distance="10%" bottom>
              <Heading type="h4" bold color="white" className={styles.heading}>
                {content.goalsTitle}
                <span className={styles.dot}>.</span>
              </Heading>
            </Fade>

            <Fade delay={200} distance="10%" bottom>
              <Text color="white" size="22" splitParagraphs className={styles.description}>
                {content.goalsDescription.internal.content}
              </Text>
            </Fade>
          </div>
        </Container>

        <div className={styles.goalsImages}>
          {content.goalsImages.map((image, index) => (
            <Fade distance="10%" delay={(index + 1) * 300} bottom key={image.fluid.src}>
              <Img
                fluid={image.fluid}
                alt={image.description}
                fadeIn={false}
                imgStyle={{ objectFit: 'contain', objectPosition: 'right' }}
                className={styles.goalsImagesImage}
              />
            </Fade>
          ))}
        </div>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['eqi-secondary'])
            setNavbarLight(false)
          }}
        />
      </section>

      <section className={styles.tech}>
        <div className={styles.techImagesWrapper}>
          {content.techImages.map((image, index) => (
            <Fade
              distance={index === 0 ? '10%' : '20%'}
              delay={(index + 1) * 300}
              bottom
              key={image.fluid.src}
            >
              <Img
                fluid={image.fluid}
                alt={image.description}
                fadeIn={false}
                imgStyle={{
                  objectFit: 'contain',
                  objectPosition: 'left bottom',
                }}
                className={styles.techImagesWrapperImage}
              />
            </Fade>
          ))}
        </div>

        <Container>
          <div className={styles.techTextWrapper}>
            <Fade distance="10%" bottom>
              <Heading type="h4" bold color="white" className={styles.heading}>
                {content.techTitle}
                <span className={styles.dot}>.</span>
              </Heading>
            </Fade>

            <Fade delay={200} distance="10%" bottom>
              <Text color="white" size="22" splitParagraphs className={styles.description}>
                {content.techDescription.internal.content}
              </Text>
            </Fade>
          </div>
        </Container>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['eqi-primary'])
            setNavbarLight(false)
          }}
        />
      </section>

      <section className={styles.quote}>
        <div className={styles.quoteContentWrapper}>
          <Fade distance="10%" bottom>
            <Quote
              mobileComplete
              color="eqiPrimary"
              text={quote.text.internal.content}
              user={quote.user}
              userPhoto={quote.userImage.fixed}
              userJob={quote.jobDescription}
            />
          </Fade>
        </div>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['color-gray-lightest'])
            setNavbarLight(true)
          }}
        />
      </section>

      <section className={styles.results}>
        <div className={styles.resultsBg}>
          <Container className={styles.resultsContentWrapper}>
            <Row>
              <Col xs={12} sm={12} md={3} lg={3} className={styles.resultsColumnWrapper}>
                <Fade distance="10%" bottom>
                  <Heading type="h4" bold color="white" className={styles.resultsHeading}>
                    <FormattedMessage id="casesCommon.results" />
                    <span className={styles.dot}>.</span>
                  </Heading>
                </Fade>
              </Col>

              {[results1, results2, results3].map((result, index) => (
                <Col
                  xs={12}
                  sm={12}
                  md={3}
                  lg={3}
                  className={styles.resultsColumnWrapper}
                  // eslint-disable-next-line react/no-array-index-key
                  key={`Results ${index}`}
                >
                  <div className={styles.resultsTextWrapper}>
                    <Fade distance="10%" bottom delay={(index + 1) * 200}>
                      <div className={styles.resultsText}>
                        {documentToReactComponents(
                          rawToJSON(content[`results${index + 1}`].raw),
                          resultsOptions
                        )}
                      </div>
                    </Fade>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </div>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['eqi-primary'])
            setNavbarLight(false)
          }}
        />
      </section>

      <div className={styles.portfolio}>
        <ProudCases currentProject="EQI" caseStudies={content.proudCases} extendedCTA />

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['color-gray-lightest'])
            setNavbarLight(true)
          }}
        />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query EQICaseStudyContent($locale: String) {
    contentfulEqiCaseStudy(fields: { localeKey: { eq: $locale } }) {
      pageTitle
      pageDescription
      pageThumbnail {
        file {
          url
        }
      }
      heroDescription {
        internal {
          content
        }
      }
      heroImages {
        description
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      challengeTitle
      challengeDescription {
        internal {
          content
        }
      }
      challengeImage {
        description
        file {
          url
        }
      }
      userExperienceTitle
      userExperienceDescription {
        internal {
          content
        }
      }
      userExperienceImages {
        description
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      userExperienceBg {
        description
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      visualDesignTitle
      visualDesignDescription {
        internal {
          content
        }
      }
      paymentsTitle
      paymentsDescription {
        internal {
          content
        }
      }
      paymentsImages {
        description
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      paymentsBg {
        description
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      simulationTitle
      simulationDescription {
        internal {
          content
        }
      }
      simulationImage {
        description
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      simulationBg {
        description
        file {
          url
        }
      }
      simulationBgMobile {
        description
        file {
          url
        }
      }
      goalsTitle
      goalsDescription {
        internal {
          content
        }
      }
      goalsImages {
        description
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      techTitle
      techDescription {
        internal {
          content
        }
      }
      techImages {
        description
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      quote {
        id
        jobDescription
        user
        text {
          internal {
            content
          }
        }
        userImage {
          fixed(width: 64, height: 64, quality: 80) {
            src
            srcSet
          }
        }
      }
      results1 {
        raw
      }
      results2 {
        raw
      }
      results3 {
        raw
      }
      proudCases {
        id
        title
        main
        platforms {
          internal {
            content
          }
        }
        projectColor
        projectName
        thumbnail {
          description
          fixed {
            src
            srcSet
          }
          file {
            details {
              image {
                width
                height
              }
            }
          }
        }
      }
    }
  }
`

EQICaseStudy.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
}

export default EQICaseStudy
